.activityPage {
  width: 100%;
  height: calc(100% - 64px);
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #111111;

  scrollbar-width: 0;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.activityPage__header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  background: linear-gradient(0deg, transparent 0%, rgba(0, 0, 0, 1) 80%);

  h2 {
    font-size: 18px;
    font-weight: 600;
  }
}
