.transformPage__loading {
  display: flex;
  width: calc(100% - 20px);
  height: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  // border: 3px solid var(--color-primary);
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-sizing: border-box;
  z-index: 1;

  .bar {
    height: 100%;
    width: 30%;
    background-color: var(--color-primary);
    border-radius: 4px;
    transition: width 0.8s;
  }

  .position {
    min-width: 1px;
    max-width: 100%;
    height: 100%;
    float: right;
    z-index: 5;
  }

  .position::before,
  .position::after {
    content: '';
    display: block;
    position: relative;
    border-radius: 0px 2px 2px 0px;
  }
  .position::before {
    background: transparent;
    height: 100%;
    box-shadow:
      0px 0px 10px var(--color-primary),
      0px 0px 10px var(--color-primary);
    z-index: 5;
  }
  /*
  .position::after {
    background:linear-gradient(to right, black 0%, transparent 100%);
    height:calc(100% + 10px + 10px);
    width:calc(100% + 10px);
    top:10px;
    left:10px;
    z-index:-3;
  }
  */
}
