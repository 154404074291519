@mixin button-styles {
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: none;
  width: 200px;
}

@mixin text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-out {
  &.base-button {
    @include button-styles;
  }
}

.user-in {
  @include button-styles;
  display: flex;
  flex-direction: row;
  justify-content: center;

  span {
    @include text-overflow;
    align-self: center;
  }

  img {
    width: 35px;
    align-self: center;
    margin-left: var(--spacing-m);
  }
}

.menu-section-header {
  opacity: 0.5;
  font-size: smaller;
  margin-top: var(--spacing-m);
}
