@import 'scss/mixins';

.conversations {
  @include blink;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  .base-button {
    margin-left: var(--spacing-s);
    padding: var(--spacing-xs) var(--spacing-s);
  }
}

.conversations--disabled {
  opacity: 0.5;
  pointer-events: none;
}
