@import 'scss/mixins';

.testSequence {
  height: calc(100% - 64px);
  overflow-y: auto;
  margin: var(--spacing-s);

  &::-webkit-scrollbar {
    display: none;
  }

  select {
    @include select-tmp-stub;
  }

  .testRow {
    text-align: right;
    min-height: 2rem;
  }
}

.testSequence__chat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-xs);

  .configButton {
    background: none;
  }
}

.testSequence .prompt {
  height: 1.5rem;
}

.testSequence--busy {
  @keyframes tempForMobileWithNoCursor {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 0.5;
    }
  }

  cursor: wait;

  * {
    cursor: wait;
    pointer-events: none;

    animation: tempForMobileWithNoCursor 2s infinite;
  }
}

.testSequence__topSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--spacing-s);

  .balances {
    opacity: 0.7;
    font-size: smaller;
    padding-right: var(--spacing-s);
    max-width: 50%;
  }
}

.testSequence__clearCache {
  font-size: x-small;
  background: none;

  &:hover {
    color: var(--color-primary);
    background: none;
  }
}

.testSequence__configDialog {
  $bgColor: #3e5060;
  margin: var(--spacing-xs);
  padding: var(--spacing-l) var(--spacing-s) var(--spacing-s) var(--spacing-s);
  background: $bgColor;
  border-radius: var(--border-radius);
  height: 100%;

  select {
    @include select-tmp-stub;
  }

  .testRow__title {
    color: #cdd2d7;
    background: $bgColor;
  }

  .testRow__content {
    font-size: smaller;
  }
}

.previewInfo {
  font-size: 0.7rem;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
