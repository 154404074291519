.postFinalPreview {
  max-width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 6px;
  position: relative;
  display: flex;
  justify-content: center;

  .postFinalPreview__background {
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 1/1;
    background-size: contain;
    background-position: 50% 50%;

    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
  }

  .postFinalPreview__text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, black 0, transparent 100%);
    padding: 10px;
    padding-bottom: 0;
    padding-top: 40px;
    text-align: center;

    p {
      background-color: rgba(0, 0, 0, 0.2);
      padding: 10px;
      border-radius: 6px;
    }
  }

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
  }
}
