@import 'scss/breakpoints';
@import 'scss/mixins';

.dialogConfirm {
  position: fixed;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;

  display: flex;
  flex-direction: column;

  background: #000;
  border: 3px solid white;
  padding: var(--spacing-m);
  border-radius: var(--border-radius);
}

.dialogConfirm__title {
  font-size: xx-large;
  font-weight: bold;
}

.dialogConfirm__body {
  margin-top: var(--spacing-l);
}

.dialogConfirm__action {
  margin-top: var(--spacing-l);
}

.dialogConfirm__ok {
  background-color: var(--color-primary);
  &:hover {
    background-color: var(--color-primary);
  }
}

.dialogConfirm__cancel {
  margin-left: var(--spacing-s);
  background: none;

  &:hover {
    background: white;
    color: black;
  }
}
