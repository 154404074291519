@import 'scss/mixins';

.scripts {
  @include blink;
}

.scripts__rhs {
  display: flex;
  justify-content: flex-end;
  width: 50%;

  select {
    @include select-tmp-stub;
    width: 100%;
  }

  .blink {
    @include blink;
    animation: blink 1s infinite;
  }
}
