@import 'scss/mixins';

.prompt {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #636363;
  border-radius: 14px;
  padding: 14px 5%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.2px;
  width: 100%;
  height: 60px;
  color: white;
  outline: none;

  &:hover {
    cursor: text;
  }
}
