.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  animation: addBackdrop 0.2s ease-out forwards;

  &.modal--closing {
    animation: removeBackdrop 0.2s ease-out forwards;
    .modal__content {
      animation: closeModal 0.2s ease-out forwards;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.modal__content {
  position: absolute;
  // top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  transform: translateY(100%);
  animation: openModal 0.2s ease-out forwards;
  &:hover {
    cursor: default;
  }
}

@keyframes addBackdrop {
  0% {
    backdrop-filter: blur(0);
  }
  100% {
    backdrop-filter: blur(4px);
  }
}

@keyframes removeBackdrop {
  0% {
    backdrop-filter: blur(4px);
  }
  100% {
    backdrop-filter: blur(0);
  }
}

@keyframes openModal {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes closeModal {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}
