.post {
  display: flex;
  width: 100%;
  height: 100%;
}

.post__background {
  position: relative;
  flex: 1;
  background-size: cover;
  background-position: center;
}
