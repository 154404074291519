.userGallery {
  position: sticky;
  top: 50px;
  width: 100%;
  height: calc(100% - 120px);
  margin-top: 50px;
}

.userGallery__tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .userGallery__tabSpacer {
    flex: 1;
  }
  .userGallery__tab {
    display: flex;
    justify-content: center;
    flex: 1;
    border-bottom: 3px solid transparent;
    transition: border-bottom 1s;

    img,
    svg {
      margin-bottom: 10px;
      width: 28px;
      stroke: white;
      transition: stroke 1s;
    }

    &:hover {
      cursor: pointer;
      svg {
        stroke: var(--color-secondary);
      }
    }

    &.userGallery__tab--active {
      border-bottom: 3px solid var(--color-primary);

      svg {
        stroke: var(--color-primary);
      }
    }
  }
}

.userGallery__grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
