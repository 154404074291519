@import 'scss/mixins';

.gateway {
  display: flex;
  flex-direction: column;
}

.gateway__item {
  select {
    @include select-tmp-stub;
    width: 100%;
  }

  label {
    opacity: 0.7;
    font-size: 0.7rem;
    font-weight: bold;
    color: var(--color-primary);
    margin-left: 0;
    margin-right: 0;
  }

  .base-button {
    font-size: 0.77rem;
    margin-left: var(--spacing-xs);
    padding: var(--spacing-xs) var(--spacing-xs);
  }
}
