.posts {
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 200%;
  height: 100%;
  margin-left: 0%;
  transition: margin-left 0.4s;
  overflow: hidden;

  &.posts--right {
    margin-left: -100%;
  }
}

.scrollList {
  width: 50%;
  overflow: scroll;
  height: 100%;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;

  scrollbar-width: 0;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.postWrapper {
  height: 100%;
  scroll-snap-align: start;
  position: relative;
}

.postsHeader {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 20px;
  left: 0;

  .postsHeader__options {
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 16px;
    padding: 0 10px;

    span {
      margin-top: 3px;
    }

    div {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 4px 10px;
      font-weight: 400;
      transition: font-weight 0.4s;

      &.active {
        font-weight: 900;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.postsHeader--remixing {
    background: red;
  }
}
