.postStyles {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 0 5px;
}

.postStyles__title {
  font-size: 20px;
  color: white;
  font-family: 'IBM Plex Mono';
  margin-bottom: 20px;
}

.postStyles__options {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 100%;
  flex-wrap: wrap;
}

.postStyles__option {
  flex: 1;
  // background-color: white;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 5px;
  padding-right: 5px;
  align-self: center;
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  min-width: 30%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  user-select: none;

  background-size: 100%;
  // background-size: cover;
  background-position: center;
  transition: background-size 0.4s;
  border: 2px solid transparent;

  span {
    padding: 4px 0;
    font-size: 10px;
    font-weight: 600;
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 0 4px;
    border-radius: 4px;
    opacity: 1;
    transition: opacity 0.4s;
  }

  &.postStyles__option--active {
    background-size: 121%;
    border: 2px solid var(--color-primary);
    span {
      background-color: var(--color-primary);
      color: white;
      opacity: 1;
    }
  }

  &:hover {
    cursor: pointer;
    background-size: 121%;

    span {
      opacity: 0;
    }
  }
}
