.commentAdd {
  display: flex;
  position: relative;
  flex-direction: row;
  margin-top: auto;
  background-color: #222;
  padding-top: 10px;
  padding-bottom: 18px;
  padding-left: 12px;
  padding-right: 12px;
  height: 50px;
}

.commentAdd__avatar {
  width: 50px;

  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
}

.commentAdd__input {
  display: flex;
  flex: 1;
  margin-left: 12px;
  padding: 9px;
  border-radius: 10px;
  background-color: #333;

  input {
    color: white;
    background: transparent;
    width: 100%;
    border: none;
    outline: none;
  }
}

.commentAdd__send {
  position: absolute;
  right: 0;
  top: 0;

  svg {
    height: 24px;
    stroke: white;
    margin-right: 10px;
  }

  img {
    height: 24px;
    margin-top: 24px;
    margin-right: 24px;

    &:hover {
      cursor: pointer;
    }
  }
}
