$color: rgba(255, 255, 255, 0.3);

.testRow {
  position: relative;
  border-top: 1px solid $color;
  padding: var(--spacing-s) var(--spacing-xs) var(--spacing-m) var(--spacing-xs);
}

.testRow__title {
  font-size: 0.7rem;
  font-weight: bold;
  background: black;
  color: $color;

  position: absolute;
  padding: 0 var(--spacing-xs);
  top: calc(-1 * var(--spacing-xs) - 2px);
  left: 0;
}

.testRow__content--disabled {
  pointer-events: none;
  opacity: 0.5;
}
