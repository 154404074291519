.userProfile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  scrollbar-width: 0;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.avatarContainer {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  background-color: transparent;
  margin-bottom: 20px;

  .avatarContainer__avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    z-index: 1;
  }

  .avatarContainer__add {
    position: absolute;
    bottom: -14px;
    z-index: 1;
  }
}

.userProfile__handle {
  position: sticky;
  top: 12px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  z-index: 99;
}

.userProfile__meta {
  display: flex;
  width: 100%;
  padding: 0 5%;
  margin-bottom: 30px;

  .userProfile__meta__column {
    display: flex;
    flex-direction: column;
    flex: 1;

    .userProfile__meta__number {
      font-size: 25px;
      text-align: center;
      font-weight: 700;
    }
    .userProfile__meta__description {
      font-size: 12px;
      text-align: center;
      font-weight: 600;
    }
  }
}

.userProfile__bio {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  p,
  div {
    font-size: 10px;
    font-weight: 600;
    line-height: 13px;
    text-align: center;
    width: 70%;
  }
}
