.navigation {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99;

  a {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .navigation__entry {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;

    svg {
      stroke: white;
      height: 40px;
      transition: stroke 1s;
    }

    &:hover {
      svg {
        stroke: var(--color-secondary);
      }
    }

    &.navigation__entry--active {
      svg {
        stroke: var(--color-primary);
      }
    }
  }
}
