.userSettings {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  width: 100%;
  height: 100%;

  .userSettings__header {
    display: flex;
    align-items: center;
    margin-top: -35px;
    margin-bottom: 35px;
    font-weight: 600;
    font-size: 18px;
    z-index: 1;
  }

  .userSettings__options {
    margin-top: 100px;
    width: 90%;
  }

  .userSettings__option {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 14px;

    img {
      margin-left: 10px;
    }

    &:hover {
      color: var(--color-primary);
      cursor: pointer;
    }
  }

  .userSettings__option--warn {
    color: rgba(255, 0, 0, 0.8);

    &:hover {
      color: red;
    }
  }
}
