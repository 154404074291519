@import 'scss/mixins';

.operators {
  @include blink;
}

.operators__cost {
  margin-top: var(--spacing-xxs);
  font-size: 0.7rem;
  color: gray;
}
