@import '~/src/scss/breakpoints';

@font-face {
  font-family: 'IBMPlexMono';
  src: url('../assets/fonts/IBMPlexMono-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'PressStart2P';
  src: url('../assets/fonts/PressStart2P-Regular.ttf') format('truetype');
}

body {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-family: 'IBMPlexMono';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  @media (max-width: $breakpoint-small) {
    width: 100%;
  }

  // Temp fake phone
  @media (min-width: $breakpoint-small) {
    position: fixed;
    top: 20px;
    bottom: 20px;
    border-radius: 20px;
    border: 2px solid white;
    width: 26vw;
    overflow: hidden;
  }
}

// prevent scrollbar causing content to jump sideways
@media screen and (min-width: 960px) {
  html {
    margin-left: calc(100vw - 100%);
    margin-right: 0;
  }
}

input[type='button'] {
  background-color: black;
  border: 3px solid white;
  padding: 9px 30px;
  border-radius: 52px;
  color: white;
  font-weight: 400;
  font-family: 'PressStart2P';
  font-size: 10px;
  margin-left: 5px;
  margin-right: 5px;

  &:hover {
    background-color: white;
    color: black;
    cursor: pointer;
  }

  &.button--disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: default;
  }
}

:root {
  --spacing-xxl: 6rem;
  --spacing-xl: 4rem;
  --spacing-l: 2rem;
  --spacing-m: 1rem;
  --spacing-s: 0.6rem;
  --spacing-xs: 0.4rem;
  --spacing-xxs: 0.2rem;

  --color-primary-base: 247, 139, 5;
  --color-primary: rgba(var(--color-primary-base), 1);
  --color-secondary-base: 181, 0, 232;
  --color-secondary: rgba(var(--color-secondary-base), 1);
  --border-radius: 12px;
}

/*
[theme='dark'] {
  --color-background-base: 17, 17, 17;
  --color-background: rgba(var(--color-background-base), 1);
  --color-background-card-base: 34, 34, 34;
  --color-card-background: rgba(var(--color-background-card-base), 1);
  --color-text: #ffffff;
}

[theme='bright'] {
  --color-background-base: 255, 255, 255;
  --color-background: rgba(var(--color-background-base), 1);
  --color-background-card-base: 240, 241, 244;
  --color-card-background: rgba(var(--color-background-card-base), 1);
  --color-text: #000000;
}
*/
