.remix {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 64px;
  height: 200px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), transparent 100%);
  text-align: center;
  transition:
    height 0.4s,
    background-color 0.4s;
  box-sizing: border-box;
  z-index: 1;

  &.open {
    height: 100%;
    max-height: 100%;
    background-color: rgba(34, 34, 34, 0.8);

    .remix__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 20px;
      backdrop-filter: blur(4px);
      // background: linear-gradient(0deg, transparent 0%, rgba(34, 34, 34, 1) 40%);

      .icon {
        margin-bottom: 22px;
      }

      h2 {
        margin: 0;
        font-family: 'PressStart2P';
      }
    }

    .remix__content__prompt {
      margin: 20px 0;

      &.remix__content__prompt--hidden {
        height: 0;
        overflow: hidden;
      }
    }

    .prompt {
      background-color: #636363;
      padding: 14px 5%;
    }
  }

  .remix__header {
    display: flex;
    padding-top: 20px;
    justify-content: center;
    width: 100%;
    transition:
      padding 0.4s,
      backgriound 0.4s;
  }

  .remix__content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    // padding-bottom: 60px;
    box-sizing: border-box;

    .remix__content__page {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      // max-height: 100%;
      margin-bottom: auto;
      transform: scaleY(100%);
      transition: transform 0.2s;

      &.remix__content__page--closed {
        transform: scaleY(0);
        height: 0%;
        display: none;
      }
    }

    .remix__content__row {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      &.remix__content__row--next {
        bottom: 0;
        height: 80px;
      }
    }

    .remix__content__retry {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: black;
      border: 3px solid white;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      font-weight: 400;
      font-family: 'PressStart2P';
      font-size: 10px;
      user-select: none;

      img {
        width: 24px;
        filter: hue-rotate(30deg) invert(100%);
        image-rendering: pixelated;
        margin-top: -6px;
        margin-bottom: 6px;
      }

      &:hover {
        cursor: pointer;
        background: white;
        color: black;
        img {
          filter: unset;
        }
      }
    }
  }

  .icon {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    margin-bottom: 0;
    transition: margin-bottom 0.4s;

    img {
      width: 50px;
      height: 50px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .prompt {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    padding: 8px 5%;
    font-weight: 400;
    font-size: 14px;
    line-height: 18.2px;
    height: 70px;
    width: 100%;
    box-sizing: border-box;
    background-color: unset;

    transition:
      background 0.4s,
      padding 0.4s;
  }
}
