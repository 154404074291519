.comment {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
}

.comment__avatar {
  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
}

.comment__text {
  flex: 1;
  margin-left: 12px;
}

.comment__text__header {
  display: flex;
  flex-direction: row;
}

.comment__text__username {
  color: #797979;
  margin-right: 8px;
  font-size: 11px;
  font-weight: 400;
}

.comment__text__timestamp {
  color: #797979;
  opacity: 0.6;
  font-size: 11px;
  font-weight: 400;
}

.comment__comment {
  font-size: 11px;
  font-weight: 400;
  margin-top: 2px;
}

.comment__remix__wrapper {
  max-width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 6px;
  position: relative;
  display: flex;
  justify-content: center;

  .comment__remix {
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 1/1;
    background-size: contain;
    background-position: 50% 50%;

    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
  }

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
  }

  .comment__remix__text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, black 0, transparent 100%);
    padding: 10px;
    padding-bottom: 0;
    padding-top: 40px;
    text-align: center;

    p {
      background-color: rgba(0, 0, 0, 0.2);
      padding: 10px;
      border-radius: 6px;
    }
  }
}

.comment__actions {
  display: flex;
  margin-top: 2px;
  margin-bottom: 4px;
}

.comment__action {
  display: flex;
  align-items: center;
  margin-top: 4px;
  color: #aaa;
  opacity: 0.6;
  margin-right: 14px;
  font-size: 11px;
  font-weight: 400;

  img {
    height: 12px;
    margin-right: 6px;
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
    color: var(--color-primary);
  }
}

.comment__like {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  margin-top: 15px;
  font-weight: 600;
  color: white;
  margin-left: 15px;

  svg {
    height: 24px;
    margin-bottom: 2px;
    stroke: white;
  }

  &:hover {
    cursor: pointer;
  }
}

.comment__liked {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  margin-top: 15px;
  font-weight: 600;
  color: #ff354a;
  margin-left: 15px;

  svg {
    height: 24px;
    margin-bottom: 2px;
    stroke: white;
  }

  &:hover {
    cursor: pointer;
  }
}

.comment__remixWrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  // display:none;
  background: red;
  opacity: 999;
}
