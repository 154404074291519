.wallet-balance {
  display: flex;
  justify-content: flex-end;

  label {
    margin-left: var(--spacing-xs);
    min-width: 2ch;
  }

  &:hover {
    cursor: pointer;
  }
}

.wallet-balance--updating {
  animation: blink 0.5s infinite;
}

.wallet-balance__entries {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  font-size: 0.7rem;
  opacity: 0.5;
}
