@mixin disabled {
  pointer-events: none;
  opacity: 0.3;
}

.signInPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  hr {
    width: 80%;
  }
}

.signInPage__header {
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1;

  .header__back {
    display: flex;
    flex: 1;
    margin: 10px;

    img {
      margin: -20px;
      margin-right: auto;
      padding: 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.signInPage__avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: darkgray;

  img {
    width: 80px;
    height: 80px;
  }
}

.signInPage__avatar--active {
  background: none;

  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
}

.signInPage__wallet {
  .wallets {
    position: unset;
    padding: var(--spacing-m);
  }

  .disconnectBtn {
    background: none;
    color: var(--color-primary);
    font-size: smaller;
    margin-top: var(--spacing-m);
  }
}

.signIbPage__wallet--disabled {
  @include disabled;
}

.signInPage__eo {
  display: flex;
  flex-direction: column;
  align-items: center;

  .eo__title {
    font-size: xx-large;
    font-weight: bold;
    color: var(--color-primary);
    margin: var(--spacing-m);
  }

  .eo__subtitle {
    opacity: 0.75;
    max-width: 80%;
    text-align: center;
    margin-bottom: var(--spacing-m);
  }

  .base-button {
    font-size: larger;
  }
}

.signInPage__eo--disabled {
  @include disabled;
}

.signInPage__eo--busy {
  animation: pulse 2s infinite;
}
