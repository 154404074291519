.post__actions {
  position: absolute;
  right: 10px;
  bottom: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 9px;
  border-radius: 10px;
  transition: bottom 0.4s;

  &.post__actions--remixing {
    bottom: calc(100% + 320px);
  }
}

.post__action {
  text-align: center;
  position: relative;
  width: 50px;
  height: 50px;
  margin: 5px 0;

  .post__action__avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .post__action__icon,
  svg {
    width: 50px;
    image-rendering: pixelated;
  }

  svg {
    stroke: white;
  }

  .post__action__original {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    object-fit: cover;
  }

  .post__action__source {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 0 6px 0 6px;
    left: 0;
    bottom: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.post__action__spacer {
  width: 100%;
  margin: 5px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.hearts {
  bottom: 0;
  left: -20px;
  width: calc(100% + 40px);
  min-height: 400px;
  position: absolute;
  z-index: 88;
}

:root {
  --reaction-animation-type: forwards;
}

.heart {
  background-color: var(--color-heart);
  position: absolute;
  height: 20px;
  width: 20px;
  opacity: 0;
  animation: floating-heart-a 14s var(--reaction-animation-type) cubic-bezier(0.5, 0.5, 0.5, 0.5);
  image-rendering: pixelated;
}

.heart:nth-child(1) {
  animation-delay: 1s;
}

.heart:nth-child(2) {
  animation: floating-heart-b 14s var(--reaction-animation-type) cubic-bezier(0.5, 0.5, 0.5, 0.5);
  animation-delay: 2.5s;
}

.heart:nth-child(3) {
  animation-delay: 3.5s;
}

.heart:nth-child(4) {
  animation: floating-heart-b 14s var(--reaction-animation-type) cubic-bezier(0.7, 0.7, 0.7, 0.7);
  animation-delay: 8s;
}

.heart:nth-child(5) {
  animation-delay: 4.8s;
}

.heart:nth-child(6) {
  animation: floating-heart-b 14s var(--reaction-animation-type) cubic-bezier(0.6, 0.6, 0.6, 0.6);
  animation-delay: 5s;
}

.heart:nth-child(7) {
  animation-delay: 5.5s;
}

.heart:nth-child(8) {
  animation: floating-heart-b 14s var(--reaction-animation-type) cubic-bezier(0.2, 0.2, 0.2, 0.2);
  animation-delay: 5.7s;
}

.heart:nth-child(9) {
  animation-delay: 6.1s;
}

.heart:nth-child(10) {
  animation: floating-heart-b 14s var(--reaction-animation-type) cubic-bezier(0.5, 0.5, 0.5, 0.5);
  animation-delay: 6.4s;
}

.heart:nth-child(11) {
  animation-delay: 6.7s;
}

.heart:nth-child(12) {
  animation: floating-heart-b 14s var(--reaction-animation-type) cubic-bezier(0.6, 0.6, 0.6, 0.6);
  animation-delay: 7s;
}

.heart:nth-child(13) {
  animation-delay: 7.1s;
}

.heart:nth-child(14) {
  animation: floating-heart-b 14s var(--reaction-animation-type) cubic-bezier(0.4, 0.4, 0.4, 0.4);
  animation-delay: 7.7s;
}

.heart:nth-child(15) {
  animation-delay: 8s;
}

.heart:nth-child(16) {
  animation: floating-heart-b 14s var(--reaction-animation-type) cubic-bezier(0.9, 0.9, 0.9, 0.9);
  animation-delay: 8.2s;
}

@keyframes floating-heart-a {
  0% {
    opacity: 0;
    bottom: 0%;
    left: 50%;
  }
  10% {
    opacity: 1;
    bottom: 20%;
    left: 70%;
  }
  20% {
    bottom: 40%;
    left: 10%;
  }
  30% {
    bottom: 60%;
    left: 50%;
  }
  40% {
    opacity: 1;
    bottom: 80%;
    left: 5%;
  }
  48% {
    opacity: 0;
    bottom: 100%;
    left: 60%;
  }
  100% {
    opacity: 0;
    bottom: 100%;
    left: 80%;
  }
}

@keyframes floating-heart-b {
  0% {
    opacity: 0;
    bottom: 0%;
    left: 50%;
  }
  10% {
    opacity: 1;
    bottom: 18%;
    left: 30%;
  }
  20% {
    bottom: 36%;
    left: 80%;
  }
  30% {
    bottom: 60%;
    left: 50%;
  }
  40% {
    opacity: 1;
    bottom: 80%;
    left: 85%;
  }
  48% {
    opacity: 0;
    bottom: 100%;
    left: 40%;
  }
  100% {
    opacity: 0;
    bottom: 100%;
    left: 10%;
  }
}
