.transformPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 64px);

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.transformPage__input {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.transformPage__prompt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.transformPage__types {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.transformPage__styles {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.transformPage__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  input {
    &:disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
