@import 'scss/breakpoints';
@import 'scss/mixins';

.wallets {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mobile {
    justify-content: flex-end;
  }
}

.wallets--disabled {
  pointer-events: none;
  opacity: 0.5;
}

.wallets__header {
}

.wallets__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: var(--spacing-m);
  max-width: 80%;
}

.wallets__list-item {
  margin-top: var(--spacing-m);
  padding: var(--spacing-m);
  width: 100%;
  display: flex;
  font-size: larger;
  align-items: center;
  border: 1px solid #444;
  border-radius: var(--border-radius);
  background-color: rgba(40, 40, 40, 0.8);

  img {
    padding: var(--spacing-xs);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: gray;
  }

  span {
    font-size: larger;
    margin-left: var(--spacing-m);
  }

  &:hover {
    cursor: pointer;
    color: var(--color-primary);
  }
}
