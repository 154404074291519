.postTypes {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.postTypes__wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  align-items: center;
}

.postTypes__type {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 2px solid white;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  user-select: none;

  &.postTypes__type--disabled {
    opacity: 0.4;
  }
}

.postTypes__indicator {
  width: 60%;
  padding-bottom: 8px;
  border-bottom: 2px solid white;
  border-color: var(--color-primary);
}
