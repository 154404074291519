@import 'scss/mixins';

.chat {
  display: flex;
  justify-content: space-between;
  max-height: 100%;
}

.chat__content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  padding: var(--spacing-xs);
  overflow-y: auto;
}

.chat__reload {
  @include blink;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;

  .base-button {
    color: #aaaaaa;
    font-size: larger;
    background: none;

    &:hover {
      background: none;
    }
  }
}

.chat__loadMore {
  @include blink;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;

  .base-button {
    color: rgba(var(--color-primary-base), 0.8);
    background: none;

    &:hover {
      color: var(--color-primary);
      background: none;
    }
  }
}

.chatRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 14px;
  border: 2px solid transparent;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border: 2px solid #636363;
  }
}

.chatPrompt {
  align-self: center;
  margin-bottom: var(--spacing-s);
  width: 100%;

  .chatPrompt__text {
    background-color: #636363;
    padding: var(--spacing-xs);
    border-radius: var(--border-radius);
    font-size: 12px;
    cursor: crosshair;
  }

  .chatPrompt__timestamp {
    margin-top: -4px;
    margin-bottom: 4px;
    font-size: 10px;
    opacity: 0.6;
  }
}

.chatResults {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;

  img {
    width: 60px;
    margin-right: 6px;
    border-radius: 4px;
    border: 2px solid transparent;

    &:hover {
      border: 2px solid var(--color-primary);
    }
  }

  .chatResults__status {
    @include blink;
    font-size: smaller;
    text-align: center;
    min-height: 2rem;

    &:hover {
      cursor: pointer;
      background: rgba(255, 255, 255, 0.1);
    }
  }

  .chatResults__timestamp {
    color: gray;
    font-size: smaller;
  }

  .chatResults__operator {
    color: burlywood;
  }
}
