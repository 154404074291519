.postPreviews {
  display: flex;
  flex-wrap: wrap;
  line-height: 0;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;

  .postPreviews__preview {
    position: relative;
    width: calc(50% - 9px);
    aspect-ratio: 1/1;
    margin-bottom: 10px;
    border: 3px solid rgba(255, 255, 255, 0.4);
    background-size: cover;
    background-position: center;
    overflow: hidden;

    .postPreviews__preview__background {
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 100%;
      backdrop-filter: blur(0px);
      transition: backdrop-filter 0.4s;

      img {
        width: 100%;
        min-height: 100%;
        transition: transform 0.4s;
      }
    }

    &:nth-child(1),
    &:nth-child(3) {
      margin-right: auto;
    }

    &.postPreviews__preview--active {
      border: 3px solid var(--color-primary) !important;

      .postPreviews__preview__background {
        backdrop-filter: blur(4px);

        img {
          transform: scale(0.7);
        }
      }
    }

    &:hover {
      cursor: pointer;
      border: 3px solid rgba(255, 255, 255, 1);

      .postPreviews__preview__background {
        backdrop-filter: blur(4px);

        img {
          transform: scale(0.7);
        }
      }
    }
  }
}

.postPreviews__preview__noise {
  position: absolute;
  display: flex;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200%;
  padding: 0;
  background: rgba(var(--color-primary-base), 0.3) url('/assets/images/noise.png') repeat 0 0;
  background-repeat: repeat;
  animation: bg-noise 0.2s infinite;
  opacity: 0.9;
  visibility: visible;
}

@keyframes bg-noise {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -5%);
  }
  20% {
    transform: translate(-10%, 5%);
  }
  30% {
    transform: translate(5%, -10%);
  }
  40% {
    transform: translate(-5%, 15%);
  }
  50% {
    transform: translate(-10%, 5%);
  }
  60% {
    transform: translate(15%, 0);
  }
  70% {
    transform: translate(0, 10%);
  }
  80% {
    transform: translate(-15%, 0);
  }
  90% {
    transform: translate(10%, 5%);
  }
  100% {
    transform: translate(5%, 0);
  }
}
