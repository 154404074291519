.profilePage {
  flex: 1;
  width: 100%;
  height: calc(100% - 64px);
  box-sizing: border-box;
  // padding-bottom:200px;
  background-color: #000;
}

.profilePage__header {
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 0;
  right: 0;
  z-index: 1;
  padding-bottom: 20px;
  background: linear-gradient(0deg, transparent 0%, rgba(0, 0, 0, 1) 60%);

  .profilePage__header__back {
    display: flex;
    flex: 1;
    margin: 10px;

    img {
      margin: -20px;
      margin-right: auto;
      padding: 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .profilePage__header__settings {
    display: flex;
    flex: 1;
    margin: 10px;

    img {
      margin: -20px;
      margin-left: auto;
      padding: 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.profilePage__pages {
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 200%;
  height: 100%;
  transition: margin-left 0.4s;

  &.profilePage__pages--settings {
    margin-left: -100%;
  }

  .profilePage__pages__page {
    display: flex;
    width: 100%;
    height: 100%;
  }
}
