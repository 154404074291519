.activityRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    background-color: black;
  }
}

.activityRow__avatar {
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50px;
  overflow: hidden;
  flex-shrink: 0;

  img {
    width: 50px;
    height: 50px;
  }
}

.activityRow__text {
  flex: 1;
  // display: flex;
  // flex-wrap: wrap;

  span {
    display: inline;
    // display: block;
    word-wrap: break-word;
    white-space: normal;

    &:first-child {
      color: white;
      margin-right: 6px;
    }

    &:last-child {
      // display: flex;
      // flex-wrap: wrap;
      color: #aaa;
    }
  }
}

.activityRow__timestamp {
  width: 40px;
  justify-content: flex-end;
  color: var(--color-primary);
}

.activityRow__preview {
  margin-right: 10px;

  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 6px;
  }
}
