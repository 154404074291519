.comments {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 16px 16px 0 0;
  background-color: #111;
}

.comments__header {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
}

.comments__header__icon {
  svg {
    height: 16px;
    margin-left: 10px;
    stroke: white;
  }
}

.comments__header__title {
  font-weight: 400;
  font-size: 16px;
}

.comments__header__close {
  padding: 10px;
  margin: -10px;
  margin-right: 0;

  &:hover {
    cursor: pointer;
    color: var(--color-primary);
  }
}

.comments__container {
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 20px;

  scrollbar-width: 0;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
