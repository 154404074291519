.base-button {
  font-size: 0.875rem;
  line-height: 1.5;
  background-color: #007fff;
  color: white;
  border-radius: 8px;
  font-weight: 600;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 150ms ease;
  border: none;

  &:hover {
    background-color: #0072e5;
  }

  &.active {
    background-color: #0059b2;
  }

  &.focus-visible {
    box-shadow:
      0 4px 20px 0 rgb(61 71 82 / 0.1),
      0 0 0 5px rgb(0 127 255 / 0.5);
    outline: none;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
