a {
  line-height: 0;
}

.tilePreview {
  position: relative;

  img {
    object-fit: cover;
  }
}

.tilePreview__tile {
  width: 140px;
  height: 140px;
}

.tilePreview__tile--large {
  width: 280px;
  height: 280px;
}

.tilePreview__tile--height {
  width: 140px;
  height: 280px;
}

.tilePreview__meta {
  position: absolute;
  left: 4px;
  bottom: 4px;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 4px;
  box-sizing: border-box;
  border-radius: 6px;

  img {
    height: 8px;
    margin-right: 4px;
  }

  span {
    font-size: 10px;
    line-height: 10px;
    font-weight: 400;
    color: white;
    padding: 0;
  }
}
