@import 'scss/mixins';

@mixin input-style {
  color: #cdd2d7;
  background: #1a2027;
  border: #3e5060;
  box-sizing: border-box;
  width: 100%;
  border-radius: calc(var(--border-radius) / 2);
  padding: var(--spacing-xs) var(--spacing-s);
}

@mixin modal-style {
  display: flex;
  flex-direction: column;
  background: #3e5060;
  border-radius: var(--border-radius);
  align-items: center;
  margin: var(--spacing-xs);
  padding: var(--spacing-s);
  gap: var(--spacing-s);
  height: 100%;

  input {
    @include input-style;
    height: 2em;
  }

  textarea {
    @include input-style;
    height: 5em;
  }
}

.arson {
  height: calc(100% - 64px);
  overflow-y: auto;
  margin: var(--spacing-m);

  select {
    @include select-tmp-stub;
    width: 100%;
    color: #cdd2d7;
    background: #1a2027;
    border: #3e5060;
  }

  select option[value=''] {
    text-align: center;
  }

  a {
    color: cyan;
    font-size: smaller;
  }

  .row {
    border-top: 1px solid gray;
    padding: var(--spacing-s) var(--spacing-xs);
    min-height: 2rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  // --- Utils ---

  .busy {
    pointer-events: none;
    animation: blink 1s infinite;
  }

  .error {
    color: pink;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .flexRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .flexRowNoSpace {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.arson__balance {
  margin-top: var(--spacing-xs);
}

.arson.createProfile {
  @include modal-style;

  &.createProfile--busy {
    pointer-events: none;
  }

  .createProfile__create {
  }

  .createProfile__create--busy {
    pointer-events: none;
    animation: blink 1s infinite;
  }

  .createProfile__result {
  }

  .createProfile__error {
    color: pink;
  }
}

.arson.createPost {
  @include modal-style;

  &.createPost--busy {
    pointer-events: none;
  }

  .createProfile__create {
  }

  .createProfile__create--busy {
    pointer-events: none;
    animation: blink 1s infinite;
  }

  .createProfile__result {
  }

  .createProfile__error {
    color: pink;
  }
}

.arson .myStuff {
  .myStuff__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .myStuff__viewItem {
    font-weight: bold;
    padding: var(--spacing-xs) var(--spacing-s);
    margin-left: var(--spacing-xs);
    color: gray;

    &:hover {
      cursor: pointer;
    }
  }

  .myStuff__actionBtn {
    margin-left: var(--spacing-xs);
    font-size: 0.7rem;
    padding: var(--spacing-xs) var(--spacing-s);
  }

  .myStuff__error {
    color: pink;
    font-size: smaller;
    text-align: right;
    margin-top: var(--spacing-s);
  }
}

.arson .cmt {
  display: flex;
  margin-top: var(--spacing-s);

  .cmt__indent {
  }

  .cmd__content {
    background: #424a53;
    border-radius: calc(var(--border-radius) / 2);
    padding: var(--spacing-xs);
    font-size: 0.8rem;

    &.cmd__content--child {
      background: #3e5060;
    }

    &:hover {
      cursor: pointer;
      color: black;
      background: var(--color-primary);
    }
  }
}
