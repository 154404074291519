@import 'scss/mixins';

@mixin input-style {
  color: #cdd2d7;
  background: #1a2027;
  border: #3e5060;
  box-sizing: border-box;
  width: 100%;
  border-radius: calc(var(--border-radius) / 2);
  padding: var(--spacing-xs) var(--spacing-s);
}

@mixin modal-style {
  display: flex;
  flex-direction: column;
  background: #3e5060;
  border-radius: var(--border-radius);
  align-items: center;
  margin: var(--spacing-xs);
  padding: var(--spacing-s);
  gap: var(--spacing-s);
  height: 100%;

  input {
    @include input-style;
    height: 2em;
  }

  textarea {
    @include input-style;
    height: 5em;
  }
}

.createComment {
  @include modal-style;
  align-items: flex-start;

  &.createComment--busy {
    pointer-events: none;
  }
}

.createComment__readOnly {
  label {
    color: #cdd2d7;
    font-size: 0.7rem;
  }
  div {
    font-size: 0.7rem;
  }
}

.createComment__submit {
  &.createComment__submit--busy {
    pointer-events: none;
    animation: blink 1s infinite;
  }
}

.createComment__result {
  font-size: 0.7rem;
}

.createComment__error {
  color: pink;
}
