.discoverGrid {
  display: flex;
  flex-direction: column;
}

.discoverGrid__row {
  display: flex;
}

.userGallery__grid__row {
  display: flex;
  flex-direction: row;
}
