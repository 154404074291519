.menu-button {
  line-height: 1.5;
  //background-color: var(--bg-color);
  background-color: #007fff;
  color: white;
  font-weight: 600;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 150ms ease;
  border-radius: 10px;
  border: none;

  &:hover {
    background-color: #0072e5;
  }

  &.active {
    background-color: #0059b2;
  }

  &:focus-visible {
    //box-shadow:
    //  0 4px 20px 0 rgb(61 71 82 / 0.1),
    //  0 0 0 5px rgb(0 127 255 / 0.5);
    outline: none;
  }
}

.menu-list {
  box-sizing: border-box;
  padding: 10px;
  margin: 10px 0;
  min-width: 200px;
  background: #24292f;
  border: 1px solid #424a53;
  border-radius: 0.75em;
  color: #afb8c1;
  overflow: auto;
  outline: 0;
  //box-shadow: 0 2px 16px #24292f;
}

.menu-item {
  list-style: none;
  padding: 10px;
  border-radius: 0.45em;
  cursor: default;
  user-select: none;
}

.menu-item:last-of-type {
  border-bottom: none;
}

.menu-item.focus-visible {
  background-color: #32383f;
  color: #afb8c1;
  outline: 0;
}

.menu-item.disabled {
  color: #424a53;
}

.menu-item:hover:not(.disabled) {
  background-color: #32383f;
  color: #afb8c1;
}
