.discoverPage {
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  height: calc(100% - 64px);
  padding-bottom: 84px;
  scrollbar-width: 0;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  h1 {
    font-family: 'PressStart2P';
    color: var(--color-primary);
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: 30px;
  }
}

.discoverPage__search {
  position: sticky;
  top: 0px;
  z-index: 1;
  padding: 20px;
  padding-bottom: 30px;
  background: linear-gradient(0deg, transparent 0%, black 90%);

  input[type='text'] {
    width: 100%;
    padding: 12px;
    padding-left: 50px;
    border-radius: 24px;
    font-family: 'IBMPlexMono';
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
  }

  svg {
    position: absolute;
    left: 32px;
    top: 30px;
    height: 28px;
    stroke: black;
  }
}

.discoverPage__gridWrapper {
  display: flex;
  justify-content: center;
}
