@import 'scss/mixins';

@mixin modal-style {
  display: flex;
  flex-direction: column;
  background: #3e5060;
  border-radius: var(--border-radius);
  align-items: center;
  margin: var(--spacing-xs);
  padding: var(--spacing-s);
  gap: var(--spacing-s);
  height: 100%;
}

.arsonDetails {
  @include modal-style;
}

.arsonDetails__text {
  height: 90%;
  width: 90%;
  overflow-y: auto;
  padding: var(--spacing-m);

  font-size: 0.7rem;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
