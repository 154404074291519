@mixin select-tmp-stub {
  color: #cdd2d7;
  background: #1a2027;
  border: #3e5060;
  box-sizing: border-box;
  width: 100%;
  height: 2rem;
  border-radius: calc(var(--border-radius) / 2);
  padding: var(--spacing-xs) var(--spacing-s);
}

@mixin blink {
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }

  .blink {
    animation: blink 1s infinite;
  }
}

@mixin mobile {
  // Temp fake phone
  @media (max-width: $breakpoint-small) {
    @content;
  }
}
